.RecentReads .postContent
{
    width: 75%;
    margin: auto;
    font-size: 1.1rem;
}
.RecentReads .box
{
    width: 100%;
    height: 20em;
    display: flex;
    position: static;
}

.RecentReads .buttons
{
    width: 75%;
    margin: auto;
    text-align: right;
    margin-top: 1rem;
    
}

.RecentReads button
{
    font-size: 1rem;
    margin: .5rem;
    margin-right: 0;
    background-color: #182F23;
    color: #FFFFFF;
    padding: .5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: .5rem;
}

.RecentReads .title
{
    overflow: hidden;
    margin: 0 auto;
    text-align: center;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    width: 75%;
    font-size: 7em;
    font-family: Georgia, 'Times New Roman', Times, serif;
    filter: drop-shadow(0px 2px 2px #272727)
}
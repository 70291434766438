.About
{
    width: 100%;
    margin: auto;
    margin-top: 10rem;
}

.About h4
{
    font-size: 2.5rem;

}
.About .content-divided
{
    width: 90%;
    display: inline-block;
    margin: auto;
}

.About .text-box
{
    width: 55%;
    font-size: 1.8rem;
    float: right;
    text-align: center;
    font-weight: 500;
}

.About .image-hero
{
    width: 35%;
    margin: auto;
    float: left;
    transition: .2s;
}
.About .image-hero:hover
{
    transform: scale(1.1)
}

.About .gradient-box 
{
    width: 23.75rem;
    height: 26.25rem;
    background: linear-gradient(40deg, #0D4440 0%, #9FD2B7 51.44%, #B8EBCC 102.14%);
    box-shadow: 5px 4px 10px rgba(0, 0, 0, 0.25);
}
.About .image-box 
{
    margin-top: -27.8125rem;
    margin-left: 1.5625rem;
    width: 23.75rem;
    height: 26.25rem;
    background-image: url('../Assets/about_image_uncropped.JPG');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    box-shadow: 5px 4px 10px rgba(0, 0, 0, 0.25);
}
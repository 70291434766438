.Projects
{
    margin-top: 9rem;
    font-size: 2.5rem;
}

.Projects .recentreads
{
    background-image: url('../Assets/bubbles.png');
    background-size: contain;
    background-position: right;
    background-repeat: no-repeat;
}
.Projects .bruinsource
{
    background-image: url('../Assets/bubbles_2.png');
    background-size: contain;
    background-position: left;
    background-repeat: no-repeat;
}
.btn
{
    transition: .2s
}
.btn:hover
{
    transform: scale(1.1)
}

a
{
    color: #000000;
    text-decoration: none;
    transition: .2s;
}